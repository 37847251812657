import React, { useMemo } from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import Layout from '../layout/Layout';
import Image from '../components/Image';
import BlogPill from '../components/BlogPill';

interface IQueryResponse {
  data: {
    allMarkdownRemark: {
      nodes: IBlogPost[];
    };
  };
}

const Styled = styled.article`
  margin-top: -60px;

  .blog-header {
    width: 100vw;
    padding: 2rem;
    padding-top: calc(60px + 4rem);
    background-image: linear-gradient(90deg, #3023ae 0%, #6332a0 100%);
    height: 25rem;
  }
  .blog-header__title {
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
  .blog-header svg {
    width: 100px;
    height: 100px;
  }
  .blog-header h4 {
    background: none;
    color: ${colors.WHITE()};
    font-weight: 700;
    padding: 0.75rem;
    border: 4px solid ${colors.WHITE()};
    transition: 250ms all;
  }
  .blog-header h4:hover {
    background: ${colors.WHITE()};
    color: ${colors.PURPLE_DARK()};
  }
  .blog-header h4 span {
    font-weight: 500;
  }

  .section-header {
    font-weight: 700;
    font-size: 0.8rem;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 1rem;
    text-align: center;
  }

  .blog-featured {
    margin: auto;
    margin-top: -9rem;
    margin-bottom: 2rem;
    background: ${colors.WHITE()};
    border-radius: 5px;
    overflow: hidden;
    width: 90%;
    height: 20rem;
    max-width: 80rem;
    display: flex;
    box-shadow: ${styles.BOX_SHADOW};
    transition: 250ms all;
    cursor: pointer;
  }
  .blog-featured:hover {
    transform: translateY(-4px);
  }
  .blog-featured__image {
    flex: 0 0 50%;
  }
  .blog-featured__image .gatsby-image-wrapper {
    min-height: 100%;
  }
  .blog-featured__details {
    padding: 2rem;
    padding-top: 1.5rem;
    display: flex;
    flex-flow: column;
  }
  .blog-featured__details h3 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .blog-featured__details p {
    font-size: 1.2rem;
    line-height: 2rem;
    flex: 1 1 auto;
  }
  .blog-featured__details__author {
    display: flex;
    align-items: center;
  }
  .blog-featured__details__author__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    overflow: hidden;
  }

  .blog-posts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
    width: 90%;
    max-width: 80rem;
    margin: auto;
  }

  .blog-post {
    margin: auto;
    background: ${colors.WHITE()};
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    box-shadow: ${styles.BOX_SHADOW};
    transition: 250ms all;
    cursor: pointer;
    height: 30rem;
  }
  .blog-post:hover {
    transform: translateY(-4px);
  }
  .blog-post__image {
    flex: 0 0 50%;
    max-height: 50%;
    overflow: hidden;
  }
  .blog-post__image .gatsby-image-wrapper {
    min-height: 100%;
  }
  .blog-post__details {
    padding: 1.5rem;
    display: flex;
    flex-flow: column;
  }
  .blog-post__details h5 {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .blog-post__details p {
    font-size: 0.8rem;
    line-height: 1.3rem;
    flex: 1 1 auto;
  }
  .blog-post__details__author {
    display: flex;
    align-items: center;
  }
  .blog-fpost__details__author__avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 0.5rem;
    overflow: hidden;
  }
  .blog-posts--mini {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin: 5rem auto;
  }
  .blog-post--mini {
    border-radius: 0;
    background: none;
    box-shadow: none;
    height: auto;
    flex-flow: row;
    margin-bottom: 1rem;
  }
  .blog-post--mini:hover {
    transform: translateY(0);
  }
  .blog-post--mini .blog-post__image {
    flex: 0 0 200px;
    margin-right: 2rem;
    height: 100px;
  }
  .blog-post--mini .blog-post__details {
    padding: 0;
  }
  .blog-post--mini h5 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .blog-section-header {
    text-align: center;
    font-size: 1rem;
    margin: 2rem 0;
    margin-top: 4rem;
  }
  @media screen and (max-width: 500px) {
    .blog-featured {
      width: 95%;
      height: auto;
      flex-flow: column;
    }
    .blog-posts {
      width: 100%;
      flex-flow: column;
      grid-template-columns: 1fr;
      padding: 2rem;
    }
    .blog-post {
      height: auto;
      background: none;
      box-shadow: none;
      margin-bottom: 2rem;
    }
    .blog-post:hover {
      transform: translateY(0);
    }
    .blog-post__details {
      padding: 0;
    }
    .blog-post__image {
      display: none;
    }
    .blog-post__details h5 {
      font-size: 2.25rem;
    }
    .blog-post__details p {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
`;

const FeaturedBlogPost: React.FC<{ featured: IBlogPost }> = props => {
  const { featured } = props;
  return (
    <Link to={featured.fields.slug}>
      <div className="blog-featured">
        <div className="blog-featured__image">
          <Image src={featured.frontmatter.image} />
        </div>
        <div className="blog-featured__details">
          <BlogPill {...featured.frontmatter.tag} />
          <h3>{featured.frontmatter.title}</h3>
          <p>{featured.excerpt}</p>
          <div className="blog-featured__details__author">
            <div className="blog-featured__details__author__avatar">
              <Image src={featured.frontmatter.author.avatar} />
            </div>
            <label>
              {featured.frontmatter.author.name}{' '}
              <span className="text-gray">
                on {featured.frontmatter.published}
              </span>
            </label>
          </div>
        </div>
      </div>
    </Link>
  );
};

const BlogPost: React.FC<{ post: IBlogPost; mini: boolean }> = props => {
  const { post, mini } = props;
  return (
    <Link to={post.fields.slug}>
      <div className={`blog-post blog-post--${mini ? 'mini' : 'full'}`}>
        <div className="blog-post__image">
          <Image src={post.frontmatter.image} />
        </div>
        <div className="blog-post__details">
          <BlogPill {...post.frontmatter.tag} />
          <h5>{post.frontmatter.title}</h5>
          <p>{post.excerpt}</p>
          <div className="blog-post__details__author">
            <div className="blog-post__details__author__avatar">
              <Image src={post.frontmatter.author.avatar} />
            </div>
            <label>
              {post.frontmatter.author.name}{' '}
              <span className="text-gray">on {post.frontmatter.published}</span>
            </label>
          </div>
        </div>
      </div>
    </Link>
  );
};

const Blog: React.FC<IQueryResponse> = props => {
  const [posts, featured] = useMemo(() => {
    const all = [...props.data.allMarkdownRemark.nodes];
    const featuredIndex = all.findIndex(node => node.frontmatter.featured) || 0;
    const [feat] = all.splice(featuredIndex, 1);
    return [all, feat];
  }, [props.data.allMarkdownRemark.nodes]);

  const latestPosts = posts.slice(0, 3);
  const otherPosts = posts.slice(3);

  return (
    <Layout pageTitle="The Typeahead Blog" hideLines={true} darkNav={true}>
      <Styled>
        <div className="blog-header">
          <div className="blog-header__title">
            <h4>
              Typeahead <span>Blog</span>
            </h4>
          </div>
        </div>
        <FeaturedBlogPost featured={featured} />
        <h3 className="text-caps blog-section-header">Latest Posts</h3>
        <div className="blog-posts">
          {latestPosts.map((post, i) => (
            <BlogPost key={i} post={post} mini={false} />
          ))}
        </div>
        <div className="blog-posts blog-posts--mini">
          {otherPosts.map((post, i) => (
            <BlogPost key={i} post={post} mini={true} />
          ))}
        </div>
      </Styled>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { template: { eq: "blog" } } }
      sort: { fields: frontmatter___published, order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          featured
          title
          tag {
            id
            r
            g
            b
          }
          image
          published(formatString: "MMMM Do, YYYY")
          author {
            name
            twitter
            avatar
          }
        }
      }
    }
  }
`;

export default Blog;
